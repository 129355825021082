import { appInitialized } from '@mit/aws-react'
import { useState, useEffect } from 'react'
import HomeController from '../api/HomeController'
import * as appStore from '../ts/redux/actions/app'
import { store } from '../ts/redux/store/store'
import {featureChange} from "../ts/redux/actions/app";

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const homeController: HomeController = new HomeController()

  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false
    })
  }, [])

  const initializeApp = async (): Promise<void> => {
    try {
      const auth = await homeController.initialize()

      if (auth && auth.user && auth.user.roles != null) {
        store.dispatch(appStore.rolesChange(auth.user.roles))
      }

      if (auth === 403) {
        appInitialized({
          isLoading: false,
          hasAccess: false,
          hasError: false
        })
      } else {
        let featuresList = [];
        const feature = await homeController.getFeatures()

        if (feature?.featureFlags != null) {
          featuresList.push(...feature.featureFlags)
        }

        if (auth.user.features) {
          for (const [key, value] of Object.entries(auth.user.features)) {
            if (value) {
              featuresList.push(key)
            }
          }
        }

        store.dispatch(appStore.featureChange(featuresList))

        homeController.getProfile().then(data => {
          if (data) {
            store.dispatch(appStore.userChange(data))
          }
        })

        homeController.getPicture().then(data => {
          if (data) {
            store.dispatch(appStore.pictureChange(data))
          }
        })

        setInitializeApi(true)
        appInitialized({
          isLoading: false,
          hasAccess: true,
          hasError: false
        })
      }
    } catch (e) {
      console.log('initializeApp error', e)
    }
  }

  return { initializeApp }
}
